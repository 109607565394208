.rawMaterialsContainer {
    position: absolute;
    left: 0;
    width: 100vw;
    height: 100%;
}

.CaveAndNumbersContainer {
    position: absolute;
    left: 15%;
    top: 10%;
    width: auto;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.caveIcon {
    position: relative;
    height: 100%;
    width: auto;
    opacity: 0.8;
}

.binaryCodeIcon {
    position: absolute;
    bottom: 20%;
    height: 20%;
    opacity: 1;
}

.titleDescriptionContainer {
    position: absolute;
    top: 10%;
    left: 50%;
    max-width: 35%;
    width: auto;
    height: auto;
}

.titleRawMaterialsContainer {
    position: relative;
    background-color: #102B3F;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    padding: 1%;
    border-radius: 5% / 50%;
    margin-bottom: 5%;
}

.titleRawMaterials {
    color: #fff;
    margin: 0;  
    font-size: 4vh; 
    font-weight: 600;
}

.descriptionRawMaterialsContainer {
    position: relative;
    display: flex;
    text-align: justify;
    justify-content: center;
    align-items: center;
}

.descriptionRawMaterials {
    color: aliceblue;
    margin: 0; 
    font-size: 3vh;  
}