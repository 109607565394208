.IndividualAPIContainer {
    position: inherit;
    top: 20vh;
    height: auto;
    width: 100%;
}

.nameOfIndividualAPI {
    color: #fff;
    font-size: 5vh;
    font-weight: 600;
}

.cardContainerAboutAPI {
    background-color: #102b40;
    padding: 5%;
    height: 60vh;
    align-items: center;
    justify-content: space-evenly;
    text-align: justify;
    border: solid 0.1em #004b83;
}

.cardIndividualImgContainer {
    position: relative;
    width: 90%;
}

.cardTextTitleContainer {
    display: flex;
    align-items: center;
    text-align: center;
    height: 40%;
    color: #fff;
    font-size: 5vh;
    font-weight: 500;
    margin: 0;
}

.cardTextContainer {
    position: relative;
    color: #fff;
    font-size: 2.5vh;
    margin-bottom: 5%;
}

.undertextIndividual{
    color: #fff;
    margin: 0;
    display: flex;
    align-items: center !important;
    justify-content: left !important;
    text-align: left !important;
}

/* Gallery of Images */

.galleryOfImagesIndividualContainer {
    position: inherit;
    height: auto;
}

.galleryIndividualTitle {
    margin-top: 3%;
    color: #fff;
    font-size: 5vh;
    font-weight: 600;
}

.rowIndividualContainer {
    margin-top: 3vh;
}

.carrouselIndividualContainer {
    position: relative;
    height: 100%;
    width: 100%;
}

.carrouselImageContainer1 {
    position: relative;
    height: 50%;
    width: 100%;
}

.carrouselImageContainer2 {
    position: relative;
    height: 50%;
    width: 100%;
}

.carrouselImageContainer3 {
    position: relative;
    height: 50%;
    width: 100%;
}

.carrouselImage {
    position: relative;
    height: 100%;
    width: 100%;
}