/* All Pages */

.thumbnail {
    position: absolute;
    height: 40vh;
    margin-top: 5vh;
    z-index: 5;
    background-color: #102B3F;
    padding: 5%;
}

.thumbnailStages {
    position: absolute;
    height: 40vh;
    margin-left: 56vw;
    z-index: 5;
    background-color: #102B3F;
    padding: 5%;
}

.thumbnailStages2 {
    position: absolute;
    height: 40vh;
    margin-bottom: 50vh;
    z-index: 5;
    background-color: #102B3F;
    padding: 5%;
}

.thumbnailStages3 {
    position: absolute;
    height: 40vh;
    margin-top: -40vh;
    z-index: 5;
    background-color: #102B3F;
    padding: 5%;
}

.LinksThatUseThumbnail {
    color: #fff;
    font-size: 2.5vh;
    margin: 0;
    padding: 0;
    z-index: 1000;
    text-decoration: none;
}

.LinksThatUseThumbnail:hover {
    color: #ffce2c;
}

/* First Page: Raw Materials */

.LineCreateLead {
    position: absolute;
    top: 45%;
    right: 94%;
    border: none;
    border-radius: 5%;
    width: 17vh;
    border-bottom: #05CACD solid 0.2em;
    padding: 0;
    margin: 0;
    text-align: left;
    z-index: 10000;
}

.LineCreateFile {
    position: absolute;
    top: 15%;
    left: 85%;
    border: none;
    border-radius: 5%;
    width: 15vh;
    border-bottom: #05CACD solid 0.2em;
    padding: 0;
    margin: 0;
    text-align: right;
    z-index: 10000;
}

.LineDataFields {
    position: absolute;
    bottom: 15%;
    left: 80%;
    border: none;
    border-radius: 5%;
    width: 18vh;
    border-bottom: #05CACD solid 0.2em;
    padding: 0;
    margin: 0;
    text-align: right;
    z-index: 10000;
}

/* Second Slide: APIs */

    /* Velocity API */
.LineVelocity {
    position: absolute;
    top: 4%;
    left: 66%;
    border: none;
    border-radius: 5%;
    width: 12vh;
    border-bottom: #107295 solid 0.2em;
    padding: 0;
    margin: 0;
    text-align: right;
}

    /* Mortgage Automator API */
.LineMortgageAutomator {
    position: absolute;
    top: 4%;
    right: 66%;
    border: none;
    border-radius: 5%;
    width: 25vh;
    border-bottom: #0d6e98 solid 0.2em;
    padding: 0;
    margin: 0;
    text-align: left;
}

    /* NexOne API */
.LineNexOne {
    position: absolute;
    top: 43%;
    right: 83%;
    border: none;
    border-radius: 5%;
    width: 13vh;
    border-bottom: #0e6290 solid 0.2em;
    padding: 0;
    margin: 0;
    text-align: left;
}

    /* Paybilt API */
.LinePaybilt {
    position: absolute;
    top: 43%;
    left: 83%;
    border: none;
    border-radius: 5%;
    width: 10vh;
    border-bottom: #0d6a92 solid 0.2em;
    padding: 0;
    margin: 0;
    text-align: right;
}

    /* eID-Me API */
.LineeIDMe {
    position: absolute;
    bottom: 11.5%;
    left: 67%;
    border: none;
    border-radius: 5%;
    width: 12vh;
    border-bottom: #096185 solid 0.2em;
    padding: 0;
    margin: 0;
    text-align: right;
}

    /* Chicago Title API */
.LineChicagoTitle {
    position: absolute;
    bottom: 11.5%;
    right: 67%;
    border: none;
    border-radius: 5%;
    width: 17vh;
    border-bottom: #00598b solid 0.2em;
    padding: 0;
    margin: 0;
    text-align: left;
}

    /* Terra View API*/
.LineTeraView {
    position: absolute;
    bottom: 5%;
    left: 50%;
    border: none;
    border-radius: 5%;
    width: 40vh;
    border-bottom: #00598b solid 0.2em;
    padding: 0;
    margin: 0;
    text-align: right;
}

/* Fourth Slide */

.stagesButtonConveyerBelt {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
}

/* Fourth Slide: Human Interaction */

    /* Text align left */
    .LineClients {
        position: absolute;
        top: 14%;
        right: 78%;
        border: none;
        width: 20vh;
        text-align: left;
        border-bottom: #E8EBED solid 0.2em;
        border-radius: 5%;
        padding: 0;
        margin: 0;
        z-index: 10000;
    }
    
    .LineReferals {
        position: absolute;
        top: 42%;
        right: 69%;
        border: none;
        width: 29vh;
        text-align: left;
        border-bottom: #E8EBED solid 0.2em;
        border-radius: 5%;
        padding: 0;
        margin: 0;
        z-index: 10000;
    }
    
    .LineInternalUsers {
        position: absolute;
        top: 73%;
        right: 58%;
        border: none;
        width: 32vh;
        text-align: left;
        border-bottom: #E8EBED solid 0.2em;
        border-radius: 5%;
        padding: 0;
        margin: 0;
        z-index: 10000;
    }
    
        /* Text align right */
    .LineFunders {
        position: absolute;
        top: 11%;
        left: 78%;
        border: none;
        border-radius: 5%;
        width: 17vh;
        border-bottom: #E8EBED solid 0.2em;
        padding: 0;
        margin: 0;
        text-align: right;
        z-index: 10000;
    }
    
    .LineSigners {
        position: absolute;
        top: 39%;
        left: 68%;
        border: none;
        border-radius: 5%;
        width: 25vh;
        border-bottom: #E8EBED solid 0.2em;
        padding: 0;
        margin: 0;
        text-align: right;
        z-index: 10000;
    }
    
    .LinePeripheralsUsers {
        position: absolute;
        top: 86%;
        left: 64%;
        border: none;
        border-radius: 5%;
        width: 30vh;
        border-bottom: #E8EBED solid 0.2em;
        padding: 0;
        margin: 0;
        text-align: right;
        z-index: 10000;
    }


/* Fifth Slide: Quality Assurance */

    /* Text align left */
.LineMilestoneUpdates {
    position: absolute;
    top: 15.7%;
    right: 71%;
    border: none;
    width: 24vh;
    text-align: left;
    border-bottom: #E8EBED solid 0.2em;
    border-radius: 5%;
    padding: 0;
    margin: 0;
    z-index: 10000;
}

.LineStageNotifications {
    position: absolute;
    top: 30%;
    right: 81%;
    border: none;
    width: 24vh;
    text-align: left;
    border-bottom: #E8EBED solid 0.2em;
    border-radius: 5%;
    padding: 0;
    margin: 0;
    z-index: 10000;
}

.LineFieldTriggers {
    position: absolute;
    top: 47%;
    right: 85%;
    border: none;
    width: 17vh;
    text-align: left;
    border-bottom: #E8EBED solid 0.2em;
    border-radius: 5%;
    padding: 0;
    margin: 0;
    z-index: 10000;
}

.LineDocumentUploads {
    position: absolute;
    top: 63%;
    right: 80%;
    border: none;
    width: 24vh;
    text-align: left;
    border-bottom: #E8EBED solid 0.2em;
    border-radius: 5%;
    padding: 0;
    margin: 0;
    z-index: 10000;
}

    /* Text align right */
.LineAlertNotification {
    position: absolute;
    top: 15%;
    left: 66%;
    border: none;
    border-radius: 5%;
    width: 21vh;
    border-bottom: #E8EBED solid 0.2em;
    padding: 0;
    margin: 0;
    text-align: right;
    z-index: 10000;
}

.LineFundingStates {
    position: absolute;
    top: 45%;
    left: 77%;
    border: none;
    border-radius: 5%;
    width: 20vh;
    border-bottom: #E8EBED solid 0.2em;
    padding: 0;
    margin: 0;
    text-align: right;
    z-index: 10000;
}

.LineFullReportsModule {
    position: absolute;
    top: 64.5%;
    left: 73.5%;
    border: none;
    border-radius: 5%;
    width: 24vh;
    border-bottom: #E8EBED solid 0.2em;
    padding: 0;
    margin: 0;
    text-align: right;
    z-index: 10000;
}

.LineKanbanBoards {
    position: absolute;
    top: 74%;
    left: 48.5%;
    border: none;
    border-radius: 5%;
    width: 24vh;
    border-bottom: #E8EBED solid 0.2em;
    padding: 0;
    margin: 0;
    text-align: right;
    z-index: 10000;
}