.IndividualRawMaterialsContainer {
    position: inherit;
    top: 20vh;
    height: auto;
    width: 100%;
}

.nameOfIndividualRawMaterials {
    color: #fff;
    font-size: 5vh;
    font-weight: 600;
}

.cardContainerAboutRawMaterials {
    background-color: #102b40;
    padding: 5%;
    height: 60vh;
    align-items: center;
    justify-content: space-evenly;
    text-align: justify;
    border: solid 0.1em #004b83;
}

.cardIndividualRawMaterialsImgContainer {
    position: relative;
    width: 90%;
}

.cardTextTitleRawMaterialsContainer {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 40%;
    color: #fff;
    font-size: 5vh;
    font-weight: 500;
    margin: 0;
}

.cardTextRawMaterialsContainer {
    position: relative;
    color: #fff;
    font-size: 2.5vh;
    margin-bottom: 5%;
}

.undertextIndividualRawMaterials{
    color: #fff;
    margin: 0;
    display: flex;
    align-items: center !important;
    justify-content: left !important;
    text-align: left !important;
}

/* Gallery of Images */

.galleryOfImagesIndividualRawMaterialsContainer {
    position: inherit;
    height: auto;
}

.galleryIndividualRawMaterialsTitle {
    margin-top: 3%;
    color: #fff;
    font-size: 5vh;
    font-weight: 600;
}

.rowIndividualRawMaterialsContainer {
    margin-top: 3vh;
}

.carrouselImageRawMaterialsContainer {
    position: relative;
    height: 100%;
    width: 100%;
}

.carrouselImageRawMaterials {
    position: relative;
    height: 100%;
    width: 100%;
}