.NexSemblyContainer {
    position: absolute;
    left: 500vw;
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.nexSemblyContainer {
    position: absolute;
    width: 70%;
    top: 5%;
    height: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.clawIconContainer {
    position: relative;
    height: 80%;
    z-index: 2;
}

.clawIcon {
    height: 100%;
}

/*
.docsCheckedContainer {
    position: relative;
    height: 40%;
    border: solid #2F74A7;
    background-color: #2F74A7;
    border-radius: 50%;
    padding: 3%;
}

.docsCheckedIcon {
    height: 100%;
}
*/

.titleDescriptionNexSemblyContainer {
    position: relative;
    width: 50%;
    height: auto;
    z-index: 1;
}

.titleNexSemblyContainer {
    position: relative;
    background-color: #102B3F;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    padding: 2%;
    border-radius: 5% / 50%;
    margin-bottom: 5%;
    width: 100%;
}

.titleNexSembly {
    color: #fff;
    margin: 0;   
    font-size: 4vh;
    font-weight: 600;
}

.descriptionNexsemblyContainer {
    position: relative;
    display: flex;
    text-align: justify;
    justify-content: center;
    align-items: center;
}

.descriptionNexsembly {
    color: aliceblue;
    margin: 0; 
    font-size: 3vh;  
    text-align: justify;
}