*{
  font-family: 'Lato', sans-serif;
  margin: 0;  
  padding: 0;
}

.boldTitles{
  color: #ffce2c;
}

.movingGear1 {
  position: absolute;
  left: -15%;
  height: 90%;
  z-index: -1 !important;
  color: #2F74A7;
  top: 53%;
  transform: translateY(-52%);
}

.gearIcon1 {
  height: 100%;
  transition: transform 0.3s ease-out;
} 

.movingGear2 {
  position: absolute;
  right: -15%;
  height: 90%;
  z-index: -1 !important;
  color: #2F74A7;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
}

.gear-icon2 {
  height: 100%;
  transition: transform 0.3s ease-out;
}

.movingGear3 {
  position: absolute;
  left: -15%;
  height: 90%;
  z-index: -1 !important;
  color: #2F74A7;
  top: 50%;
  transform: translateY(-52%);
}

.gear-icon3 {
  height: 100%;
  transition: transform 0.3s ease-out;
}

.StagesCSSContainer {
    position: relative;
    height: 250vh;
    width: 100%;
    padding: 0;
    background-color: #021C1B;
}