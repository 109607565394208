.navBarContainer {
    height: 20vh;
    left: 5%;
    right: 5%;
    width: 90%;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    text-align: center;
    align-items: center;
    padding: 0;
    margin: 0;
    z-index: 1;
    overflow: hidden;
}

.buttonNavBar {
    color: #fff;
    font-size: 3vh;
    text-decoration: none;
    transition-duration: 0.4s;
}

.buttonNavBar:hover {
    color: #ffce2c;
}

.buttonNavBarIMG {
    color: #fff;
    font-size: 3vh;
    text-decoration: none;
}

.buttonNavBarIMG:hover {
    text-decoration: none;
    color: #fff;
    cursor: default;
}

.imgWrapper {
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    text-align: center;
    justify-content: center;
}

.logoImg {
    height: 50%;
    margin-top: 12.5%;
}