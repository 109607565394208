.ContainerAssemblyLine {
    position: absolute;
    width: 600vw;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: #021C1B;
    z-index: 0;
    scroll-behavior: smooth;
}

.homeButtonContainer {
    position: fixed;
    height: 10%;
    left: 1%;
    width: auto;
    z-index: 1000;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.iconHomePageButton {
    text-decoration: none;
    transition-duration: 1s;
}

.iconHomePageButton:hover {
    transform: scale(1.5);
}

.iconHomePage{
    color: #fff;
}

.conveyerBelt {
    position: absolute;
    bottom: 5%;
    left: 1%;
    width: 98%;
    height: 20%;
    border-radius: 10vh;
    border: 2vh solid #125678;
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.5);
}  

.gear {
    position: absolute;
    width: 10%;
    height: 100%;
    fill: #0eadc1;
    color: #0eadc1;
    stroke: #102b40;
    stroke-width: 2em;
    animation: gear-rotate 5s linear infinite; 
    transform-origin: 50% 50%; 
}

@keyframes gear-rotate {
    from {
        transform: rotate(0deg); 
    }
    to {
        transform: rotate(360deg); 
    }
}

.gear:nth-child(1) {
    left: 0%;
}

.gear:nth-child(2) {
    left: 10%;
}

.gear:nth-child(3) {
    left: 20%;
}

.gear:nth-child(4) {
    left: 30%;
}

.gear:nth-child(5) {
    left: 40%;
}

.gear:nth-child(6) {
    left: 50%;
}

.gear:nth-child(7) {
    left: 60%;
}

.gear:nth-child(8) {
    left: 70%;
}

.gear:nth-child(9) {
    left: 80%;
}

.gear:nth-child(10) {
    left: 90%;
}

.binaryDocument{
    top: 65%;
    left: 40%;
    position: fixed;
    height: 10%;
    width: auto;
    fill: #fff;
    z-index: 2;
}

.createdDocument {
    top: 65%;
    left: 190vw;
    position: absolute;
    height: 10%;
    width: auto;
    fill: #fff;
    z-index: 2;
}

.buttonsArrowContainer1 {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0;
}

.buttonsArrowContainer2 {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 100vw;
    
}

.buttonsArrowContainer3 {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 200vw;
}

.buttonsArrowContainer4 {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 300vw;
}

.buttonsArrowContainer5 {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 400vw;
}

.buttonsArrowContainer6 {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 500vw;
}

.buttonsArrowContainer7 {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 600vw;
}

.buttonArrowRight {
    position: absolute;
    top: 45%;
    right: 3%;
    border: none;
    background-color: transparent;
}

.buttonArrowLeft {
    position: absolute;
    top: 45%;
    left: 3%;
    border: none;
    background-color: transparent;
}

.ArrowIcon {
    color: #fff;
    border: none;
    background-color: transparent;
}