.homeContainer {
    height: 415vh;
    max-width: 100%;
    background-color: #021C1B;
    overflow: hidden;
}

.Section0Container {
    height: 100vh;
    overflow: hidden;
    z-index: 0;
}

.gear-icon0 {
    position: relative;
    top: -40%;
    right: -50%;
    height: 135%;
    color: #2F74A7;
    overflow-x: hidden !important;
    transform-origin: center center;
    transition: transform 0.3s ease-out;
    animation: gear-rotate 10s linear infinite reverse;
}

@keyframes gear-rotate-home {
    from {
        transform: rotate(0deg); 
    }
    to {
        transform: rotate(360deg); 
    }
}

.imgBackgroundHome{
    position: relative;
    width: 100%;
}

.homeText {
    color: #fff;
    position: absolute;
    left: 13%;
    top: 25vh;
}

.tagLineHome {
    font-size: 4vh;    
    text-shadow: 
    -0.5px -0.5px 0 #000,
	0.5px -0.5px 0 #000,
	-0.5px 0.5px 0 #000,
	0.5px 0.5px 0 #000;
}

.titleHome {
    font-size: 10vh;
    color: #fff;
    letter-spacing: 2vh;   
    text-shadow: 
    -0.5px -0.5px 0 #000,
	0.5px -0.5px 0 #000,
	-0.5px 0.5px 0 #000,
	0.5px 0.5px 0 #000; 
}

.introductionHome {
    font-size: 4vh;
    max-width: 60%;
    text-align: justify;   
    text-shadow: 
    -0.5px -0.5px 0 #000,
	0.5px -0.5px 0 #000,
	-0.5px 0.5px 0 #000,
	0.5px 0.5px 0 #000;
}

.homeButtonToAssemblyLine {
    background-image: linear-gradient(#0dccea, #0d70ea);
    border: 0;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-family: Montserrat,sans-serif;
    font-size: 1.5em;
    margin: 5px;
    padding: 10px 15px;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    text-decoration: none;
    transition-duration: 0.4s;
}

.homeButtonToAssemblyLine:hover {
    font-size: 1.7em;
    color: #fff;
}


/** 
Section 1  
    */

.smartContainer {
    position: absolute;
    top: 100vh;
    height: 100vh;
    color: #fff;
    z-index: 0;    
    display: flex;
    text-align: center;
    align-items: center !important;
    justify-content: center;
    overflow: hidden;
    right: 0%;
}

.bigTitleHomeContainer {
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: center;
}

.softwareTitleGearContainerHome {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0%;
    margin: 0;
}

.bigTitleHomeContainer{
    padding: 0;
    margin: 0;
    z-index: 4 !important;
}

.bigTitleHome {
    font-size: 10vw;
    letter-spacing: 4vh;
    padding: 0;
    margin: 0;
}

.paragraphFeaturesContainer {
    position: relative;
    border-radius: 5%;
    padding: 2%;
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: justify;
}

.paragraphFeaturesHome {
    margin: 0;
    padding: 0;
    font-size: 3vh;
}

/** 
Section 2  
    */

.smartContainer2 {
    position: absolute;
    top: 200vh;
    height: 100vh;
    color: #fff;
    z-index: 0;    
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    left: 0%;
}

.bigTitleHomeContainer2 {
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: center;
    overflow: hidden;
}

.softwareTitleGearContainerHome2 {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0%;
    margin: 0;
}

.bigTitleHomeContainer2{
    padding: 0;
    margin: 0;
    z-index: 4 !important;
}

.bigTitleHome2 {
    font-size: 6vw;
    letter-spacing: 1vW;
    padding: 0;
    margin: 0;
    z-index: 1000;
}

.paragraphFeaturesContainer2 {
    position: relative;
    border-radius: 5%;
    padding: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: justify;
    margin-left: 20%;
    width: 80%;
    height: 100%;
}

.paragraphFeaturesHome2 {
    margin: 0;
    padding: 0;
    font-size: 3vh;
}

/**
Section 3
    */

.smartContainer3 {
    position: absolute;
    top: 300vh;
    height: 100vh;
    z-index: 0;
    right: 0%; 
    color: #fff;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    overflow: hidden;  
}

.bigTitleHomeContainer3 {
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: center;
}

.softwareTitleGearContainerHome3 {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0%;
    margin: 0;
}

.bigTitleHomeContainer3 {
    padding: 0;
    margin: 0;
    z-index: 4 !important;
}

.bigTitleHome3 {
    font-size: 7vw;
    letter-spacing: 4vh;
    padding: 0;
    margin: 0;
}

.paragraphFeaturesContainer3 {
    position: relative;
    border-radius: 5%;
    padding: 2%;
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: justify;
}

.paragraphFeaturesHome3 {
    margin: 0;
    padding: 0;
    font-size: 3vh;
}