.FooterContainer{
    position: absolute;
    top: 400vh;
    margin-top: 5vh;
    height: 15vh;
    width: 100%;
    background-color: #102B3F;
    overflow: hidden;
    padding: 0;
}

.imgWrapperFooter {
    height: 15vh;
    padding: 0;
    margin: 0;
    display: flex;
    text-align: center;
    justify-content: center;
}

.buttonNavBarIMG {
    padding: 0.1%;
}

.logoImgFooter {
    height: 100%;
}

.creditsContainer {
    position: absolute;
    bottom: 0;
    right: 1%;
    margin: 0;
    padding: 0;
}

.anclaFooter {
    margin: 0;
    color: #fff;
    text-decoration: none;
}

.anclaFooter:hover {
    color: #ffce34;
}

.creditsText {
    color: #fff;
    text-decoration: none;
}

.creditsText:hover {
    color: #ffce34;
}