.QualityAssuranceContainer {
    position: absolute;
    left: 400vw;
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: top;
    justify-content: center;
}

.qualityAssuranceContainer {
    position: absolute;
    display: flex; 
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 90%;
    height: 70%;

}

.inspectorContainer {
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    height: 90%;
    left: 3%;
}

.inspectoricon {
    position: relative;
    height: 100%;
    width: 100%;
}

.titleDescriptionQualityAssuranceContainer {
    position: relative;
    max-width: 40%;
    width: auto;
    height: auto;
}

.titleQualityAssuranceContainer {
    position: relative;
    background-color: #102B3F;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    padding: 1%;
    border-radius: 5% / 50%;
    margin-bottom: 5%;  
}

.titleQualityAssurance {
    color: #fff;
    margin: 0; 
    font-size: 4vh;  
    font-weight: 600;
}

.descriptionQualityAssuranceContainer {
    position: relative;
    display: flex;
    text-align: justify;
    justify-content: center;
    align-items: center;
}

.descriptionQualityAssurance {
    color: aliceblue;
    margin: 0; 
    font-size: 3vh;  
}