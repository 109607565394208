.MenuAPIContainer {
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 0;
    background-color: #021C1B;
    overflow: hidden;
}

.menuAPIContainer {
    position: absolute;
    width: 100%;
    height: 80%;
    top: 20vh;
    color: #fff;
    margin: 0;
    padding: 0;
}

.menuAPITitleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.menuAPITitle {
    color: #fff;
    font-size: 4vh;
    text-align: center;
}

.menuImageLinesContainer {
    position: relative;
    height: 80%;
    width: 45%;
    margin-left: 27.5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.menuAPIIconJunctions {
    position: relative;
    height: 100%;  
}
