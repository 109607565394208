.AnnouncementsContainer {
    position: absolute;
    height: 230vh;
    width: 100%;
    padding: 0;
    background-color: #021C1B;
}

.announcementsContainer {
    position: relative;
    height: 50vh;
    width: 100%;
    top: 20vh;
    margin: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    text-align: center;
}

.announcementsTitle {
    color: #fff;
    text-align: center;
    font-size: 3vh;
    margin-left: 10%;
    max-width: 80%;
    margin-bottom: 5vh;
}