.HumanInteractionContainer {
    position: absolute;
    left: 300vw;
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.humanInteractionContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    height: 70%;
}

.desktopLaptopIconContainer {
    position: relative;
    height: 70%;
    left: 5%;
    width: auto;
}

.desktopLaptopIcon {
    height: 100%;
    width: 100%;
}

.titleDescriptionHumanInteractionContainer {
    position: relative;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    max-width: 50%;
    max-height: 90%;
    margin: 0;
}

.titleHumanInteractionContainer {
    position: relative;
    background-color: #102B3F;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    padding: 1%;
    border-radius: 5% / 50%;
    margin: 0;
    margin-bottom: 5%;
}

.titleHumanInteraction {
    color: #fff;
    margin: 0;   
    font-size: 4vh;
    font-weight: 600;
}

.descriptionHumanInteractionContainer {
    position: relative;
    display: flex;
    text-align: justify;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.descriptionHumanInteraction {
    color: aliceblue;
    margin: 0; 
    font-size: 3vh;  
}