.OurJunctionsContainer {
    position: absolute;
    left: 100vw;
    width: 100vw;
    height: 70%;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.APIAndNumbersContainer {
    position: relative;
    margin-left: 5%;
    height: 80%;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.APIIconJunctions {
    position: relative;
    height: 100%;  
}

/* Text */

.titleDescriptionAPIContainer {
    position: relative;
    width: 40%;
    margin-right: 5%;
    margin-top: 5%;
    height: 100%;
}

.titleOurJunctionsContainer {
    position: relative;
    background-color: #102B3F;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    padding: 1%;
    border-radius: 5% / 50%;
    margin-bottom: 5%;
}

.titleOurJunctions {
    color: #fff;
    margin: 0;
    font-size: 4vh; 
    font-weight: 600;
}

.descriptionOurJunctionsContainer {
    position: relative;
    display: flex;
    text-align: justify;
    justify-content: center;
    align-items: center;
}

.descriptionOurJunctions {
    color: aliceblue;
    margin: 0; 
    font-size: 3vh;  
}