.AllTasksContainer {
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 0;
    background-color: #021C1B;
}

.allTasksContainer {
    position: absolute;
    width: 100%;
    top: 20vh;
    color: #fff;
    margin: 0;
    padding: 0;
}

.allTasksTitleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.allTasksTitle {
    color: #fff;
    font-size: 4vh;
    text-align: center;
}

.allTasksLogosContainer {
    width: 100%;
    height: 100%;
    margin-top: 3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
