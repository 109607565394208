.OurConveyerBeltsContainer {
    position: absolute;
    left: 200vw;
    width: 100vw;
    height: 100%;
}

.titleDescriptionConveyerBeltsContainer {
    position: absolute;
    width: 100%;
    height: 20%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 0;
}

.titleConveyerBeltsContainer {
    position: relative;
    background-color: #102B3F;
    width: 65%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    padding: 1%;
    border-radius: 5% / 50%;
    margin-bottom: 1%;
}

.timeBoundContainer {
    position: absolute;
    top: 79%;
    height: 25%;
    margin-left: 40%;
    width: 20%;
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 0;
}

.taskImgContainerLastTask {
    position: relative;
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
}

.taskIconLastTask {
    height: 100%;
    color: #fff;
}

.titleConveyerBelts {
    color: #fff;
    margin: 0;   
    font-size: 4vh;
    font-weight: 600;
}

.descriptionConveyerBeltsContainer {
    position: relative;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 85%;
}

.descriptionConveyerBelts {
    color: aliceblue;
    margin: 0; 
    font-size: 2.2vh;   
}

.firstFloorContainer {
    position: absolute;
    width: 100%;
    height: 45%;
    bottom: 25%;
}

.AboveConveyerImgContainer {
    position: absolute;
    height: 13%;
    margin-left: 20%;
    width: 60%;
    margin-top: 9%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.AboveConveyerImg {
    position: absolute;
    height: 80%;
    width: 100%;
}

.leftConveyerBelt {
    position: absolute;
    left: 10%;
    width: 10%;
    height: 100%;
    border: 2vh solid #125678;
    border-bottom-width: 0;
    background-color: transparent;
    transform: skew(-40deg);
}

.rightConveyerBelt {
    position: absolute;
    right: 10%;
    width: 10%;
    height: 100%;
    border: 2vh solid #125678;
    border-bottom-width: 0;
    background-color: transparent;
    transform: skew(40deg);
}

.topRectangle {
    position: absolute;
    top: 0;
    right: 24.7%;
    width: 50.5%;
    height: 25%;
    border: 2vh solid #125678;
    border-right-width: 0;
    border-left-width: 0;
    background-color: transparent;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
}

.gearTopConveyerBelts {
    height: 100%;
    width: 33%;
    fill: #0eadc1;
    color: #0eadc1;
    stroke: #102b40;
    stroke-width: 2em;
    animation: gear-rotate 5s linear infinite; 
    transform-origin: 50% 50%; 
    margin: 0;
}

.taskImgContainer {
    position: relative;
    height: 100%;
    width: 33%;
    margin: 0;
    padding: 0;
    text-decoration: none;
}

.thumbnailLinkTasksContainer {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    text-decoration: none;
    z-index: 100;
}

.thumbnailLinkTasksContainer2 {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    text-decoration: none;
    z-index: 1000;
}

.taskIcon {
    position: inherit;
    height: 100%;
    color: #fff;
}

.taskText {
    position: inherit;
    font-size: 2.2vh;
    color: #fff;
    top: 15%;
}

.taskText:hover {
    color: #ffce2c;
}

.stateBoundDescription {
    position: absolute;
    color: #fff;
    font-size: 3vh;
    top: 20%;
}

.middleRectangle {
    position: absolute;
    top: 50%;
    left: 19%;
    width: 62%;
    height: 20%;
    background-color: transparent;
    border: 2vh solid #125678;
    border-right-width: 0;
    border-left-width: 0;
    border-bottom-width: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.gearMiddleConveyerBelts {
    height: 100%;
    width: 33%;
}

.bottomRectangle {
    position: absolute;
    top: 70%;
    right: 15.2%;
    width: 69.5%;
    height: 30%;
    background-color: transparent;
    border: 2vh solid #125678;
    border-right-width: 0;
    border-left-width: 0;
    border-bottom-width: 0;
}

.arrowFileStages {
    fill: #fff;
    stroke: #fff;
    color: #fff;
}

/* Stages Buttons */

/* Pre-File */
.stagesButtonConveyerBeltContainer {
    position: absolute;
    width: 8.5%;
    height: 100%;
    z-index: 100;
    border-top: solid #0eadc1 0.2em;
}

.stagesButtonConveyerBeltContainer:hover {
    border-top: solid #ffce2c 0.2em;
}

.stagesButtonConveyerBeltContainer:nth-of-type(1) {
    left: 0%;
}

.stagesButtonConveyerBeltContainer:nth-of-type(2) {
    left: 11.2%;
}

.stagesButtonConveyerBeltContainer:nth-of-type(3) {
    left: 22.6%;
}

.stagesButtonConveyerBeltContainer:nth-of-type(4) {
    left: 34%;
}

.stagesButtonConveyerBeltContainer:nth-of-type(5) {
    left: 45.4%;
}

.stagesButtonConveyerBeltContainer:nth-of-type(6) {
    left: 56.7%;
}

.stagesButtonConveyerBeltContainer:nth-of-type(7) {
    left: 68%;
}

.stagesButtonConveyerBeltContainer:nth-of-type(8) {
    left: 79.7%;
}

.stagesButtonConveyerBeltContainer:nth-of-type(9) {
    left: 91.2%;
} 