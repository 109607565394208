.AboutContactContainer {
    position: absolute;
    overflow-y: hidden;
    height: 100vh;
    width: 100%;
    background-color: #021C1B;
}

.contactEmail {
    color: #fff;
    transition-duration: 0.4s;
}

.contactEmail:hover {
    color: #fff;
    text-decoration: none;
}

.gear-icon1 {
    position: relative;
    top: -40%;
    left: -20%;
    height: 135%;
    color: #2F74A7;
    overflow-x: hidden !important;
    transform-origin: center center; /* añadir esta propiedad */
    transition: transform 0.3s ease-out;
    animation: gear-rotate 10s linear infinite reverse;
}

@keyframes gear-rotate-home {
    from {
        transform: rotate(0deg); 
    }
    to {
        transform: rotate(360deg); 
    }
}

.imgBackgroundAboutContact{
    position: relative;
    width: 100%;
}

.AboutContactText {
    color: #fff;
    position: absolute;
    top: 25vh;
    left: 50%;
}

.tagLineAboutContact {
    font-size: 4vh;    
    text-shadow: 
    -0.5px -0.5px 0 #000,
	0.5px -0.5px 0 #000,
	-0.5px 0.5px 0 #000,
	0.5px 0.5px 0 #000;
}

.titleAboutContact {
    font-size: 13vh;
    color: #fff;
    letter-spacing: 2vh;   
    text-shadow: 
    -0.5px -0.5px 0 #000,
	0.5px -0.5px 0 #000,
	-0.5px 0.5px 0 #000,
	0.5px 0.5px 0 #000; 
}

.introductionAboutContact {
    font-size: 3vh;
    max-width: 80%;
    text-align: justify;   
    text-shadow: 
    -0.5px -0.5px 0 #000,
	0.5px -0.5px 0 #000,
	-0.5px 0.5px 0 #000,
	0.5px 0.5px 0 #000;
}